body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.flatly {
  @import '~bootswatch/dist/flatly/bootstrap';
}

.darkly {
  @import '~bootswatch/dist/darkly/bootstrap';
}

html,
#root,
#app,
#nav-page {
  height: 100%;
}

#nav-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

#page {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.page-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
}

.content-container {
  flex: 1;
}

.list-edit-button {
  font-size: 10px !important;
  padding: 2px 4px !important;
  margin-bottom: 4px !important;
}

.navbar a:hover {
  text-decoration: none;
}
