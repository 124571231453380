.log-item {
  table {
    td:last-child {
      width: 100%;
    }
  }

  pre {
    padding: 0;
    margin: 0;
    margin-bottom: 0 !important;
  }

  .card-body {
    padding: 0 !important;
    overflow: auto;
  }
}

.flatly .log-item pre code {
  color: white;
}
