#list-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  flex-grow: 1;
  height: 100%;


  .grid-icon {
    font-size: 17px;
  }

  .ag-theme-material {
    .ag-row-selected {
      background-color: #ffcc80 !important;
    }

    .ag-row-hover {
      background-color: #ffff88;
    }
  }

  .ag-menu-option-icon {
    text-align: center;
  }

  #list-toolbar-wrapper {
    position: relative;

    #list-toolbar {
      padding: 0 0 5px 0;
    }
  }

  #grid-wrapper {
    height: 100%;
    flex-grow: 1;
  }

  .ag-theme-material .ag-row-selected {
    background-color: #eeeeee;
  }

  .ag-cell-focus {
    border: 2px solid #fff176;
  }
}

.Resizer.horizontal {
  min-height: 5px;
  background-color: #0a6ebd;
  cursor: row-resize;
}

.Pane.Pane.horizontal.Pane1 {
  padding-top: 64px;
}

.Pane.horizontal.Pane2 {
  overflow: auto;
}
